<template>
    <b-container v-if="item">
        <b-row id="titre">
            <b-col col md="6">
                <h4>Surface habitable</h4>
                <v-text-field v-model="shab" filled></v-text-field>
            </b-col>
            <b-col col md="6">
                <h4>Surface annexe</h4>
                <v-text-field v-model="annexe" filled></v-text-field>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <p class="">
                    <b>Loyer plafond : </b>
                    <span class="contenu"
                    >{{ verify_null_currency(loyer_plafond) }}/mois</span
                    >
                </p>
            </b-col>
        </b-row>
        <b-row></b-row>
    </b-container>
</template>

<script>
import {mapGetters} from 'vuex';
import * as tools from './functions_utilities';

export default {
    name: 'Pinel',
    data()
    {
        return {
            shab: 55,
            annexe: 8
        };
    },
    methods: {
        verify_null_currency: tools.verify_null_currency
    },
    computed: {
        ...mapGetters({item: 'getSelected_item'}),
        loyer_plafond() {
            let shabLogement = parseFloat(this.shab.toString().replace(',', '.'))
            let anxLogement = parseFloat(this.annexe.toString().replace(',', '.')) / 2

            if (anxLogement >= 8)
                anxLogement = 8

            let totalSU =  shabLogement + anxLogement
            let coefPinel = 19 / totalSU + 0.7
            if (coefPinel > 1.2)
                coefPinel = 1.2
            return (coefPinel * this.item.selected_pinel[0].loyer * totalSU).toFixed(1);
        }
    }
};
</script>

<style lang="scss">
#titre {
    h4 {
        text-align: left;
        color: black;
        font-weight: bold;
        border-bottom: 2px #58a4b0 solid;
        padding: 0 0.5rem;
    }
}

.qualite {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: justify;
    }
}

.potentiel {
    text-align: left;

    .label {
        font-weight: bold;
        font-size: 1.2em;
    }

    .contenu {
        text-align: left;
    }
}
</style>
